import * as React from 'react';

import { cn } from '@cardo/lib';

export function Capitalize({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <span className={cn('capitalize', className)}>{children}</span>;
}
