import { useMemo } from 'react';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import { Toggle, Tooltip } from '@cardo/ui';
import { cn } from '@cardo/lib';
import IntroOfferSection from '../components/IntroOfferSection';
import {
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';
import type { NumberInputItem, Redemption } from '../types';
import { IoInformationCircleOutline } from 'react-icons/io5';
import HTMLContent from '../../HTMLContent';

export default function CapitalOneVentureXRewardsCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    creditsError,
    setCreditsError,
    perksError,
    setPerksError,
    customCPPError,
    setCustomCPPError,
    totalCreditsValue,
    data,
    cpp,
    bonusValue,
  } = useCalculator();
  const { spendCategories, credits, perks } = state;
  const {
    spendCategoryItems,
    creditsItems,
    perksItems,
    redemptionItems,
    annualFee,
  } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      creditsItems: NumberInputItem[];
      perksItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const anniversaryBonusValue = useMemo(() => {
    if (cpp === null || cpp === undefined) return 0;
    return (cpp * 10000) / 100;
  }, [cpp]);

  const effectiveAnnualFee = useMemo(
    () => annualFee - totalCreditsValue - anniversaryBonusValue,
    [annualFee, totalCreditsValue, anniversaryBonusValue]
  );

  const {
    airportPerksValue,
    hotelPerksValue,
    otherPerksValue,
    totalPerksValue,
  }: {
    airportPerksValue: number;
    hotelPerksValue: number;
    otherPerksValue: number;
    totalPerksValue: number;
  } = useMemo(() => {
    const {
      capitalOneLounges,
      plazaPremiumLounges,
      priorityPass,
      capitalOnePremierCollection,
      hertz,
      authorizedUsers,
      otherBenefits,
    } = perks;
    const airportPerksValue =
      (capitalOneLounges || 0) +
      (plazaPremiumLounges || 0) +
      (priorityPass || 0);
    const hotelPerksValue = capitalOnePremierCollection || 0;
    const otherPerksValue =
      (hertz || 0) + (otherBenefits || 0) + (authorizedUsers || 0);
    const totalPerksValue =
      airportPerksValue + hotelPerksValue + otherPerksValue;
    return {
      airportPerksValue,
      hotelPerksValue,
      otherPerksValue,
      totalPerksValue,
    };
  }, [perks]);

  const milesEarned: {
    flights: number;
    hotels: number;
    other: number;
    total: number;
  } = useMemo(() => {
    const { flights, hotelsAndRentalCars, other } = spendCategories;
    let flightsPoints = (flights || 0) * 5;
    let hotelsPoints = (hotelsAndRentalCars || 0) * 10;
    let otherPoints = (other || 0) * 2;

    return {
      flights: flightsPoints,
      hotels: hotelsPoints,
      other: otherPoints,
      total: flightsPoints + hotelsPoints + otherPoints,
    };
  }, [spendCategories]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? milesEarned.total * 12
          : milesEarned.total) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, milesEarned.total, multipliersReccuringInterval]);

  const { year1Value, year2Value }: { year1Value: number; year2Value: number } =
    useMemo(() => {
      if (
        !state.redemption.selectedOption ||
        (state.redemption.selectedOption === 'custom' &&
          !state.redemption.customValue) ||
        cpp === 0 ||
        customCPPError
      )
        return { year1Value: 0, year2Value: 0 };

      return {
        year1Value:
          totalCreditsValue +
          bonusValue +
          categoriesSpendValue +
          totalPerksValue -
          annualFee,
        year2Value:
          (credits.travelCredits || 0) +
          anniversaryBonusValue +
          categoriesSpendValue +
          totalPerksValue -
          annualFee,
      };
    }, [
      totalCreditsValue,
      categoriesSpendValue,
      totalPerksValue,
      state.redemption,
      annualFee,
      customCPPError,
      cpp,
      bonusValue,
      anniversaryBonusValue,
      credits.travelCredits,
    ]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="The Capital One Venture X Rewards Credit Card"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="The Capital One Venture X Rewards Credit Card Intro Offer"
            colorClassName="border-b-[#0B3B5D]"
            introOffer={calculator.data?.attributes.introOfferSection}
            isMR={false}
          />
        )}
      <CalculatorSection
        heading="Credits: How Do You Value Them?"
        splitHeading={true}
        colorClassName="border-b-[#0B3B5D]"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex flex-col 2xl:grid basis-1/2 grid-cols-2 px-6 pt-10 pb-5 gap-5 2xl:gap-0">
            {creditsItems.map((creditsItem, idx) => (
              <CalculatorInputGroup
                key={`${creditsItem.label}_${idx}`}
                className="2xl:col-span-2"
                {...creditsItem}
                stateObj={credits}
                stateKey="credits"
                dispatch={dispatch}
                error={creditsError}
                setError={setCreditsError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center gap-3 py-10 px-8">
            <p>
              The Capital One Venture X annual fee is ${formatInt(annualFee)}.
            </p>
            <p>
              Given how you value the credits, your effective annual fee is:{' '}
              <span className="text-theme-blue-dark text-lg ml-2">
                ${formatInt(Math.round(effectiveAnnualFee))}
              </span>
            </p>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Miles Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-[#0B3B5D]"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-[#0B3B5D]"
                selectedOptionClassName="text-white"
              />
            </div>
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
          </div>
          <div className="flex basis-1/2 justify-center items-center">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>
                {multipliersReccuringInterval} miles earned from spend
                breakdown:
              </p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={milesEarned.hotels}
                  comparisonValue={milesEarned.total}
                  label="10X Hotels & rental cars"
                  colorClassName="bg-theme-blue-dark"
                  suffix="Miles"
                />
                <ValueWithBar
                  value={milesEarned.flights}
                  comparisonValue={milesEarned.total}
                  label="5X Flights"
                  colorClassName="bg-[#538DFF]"
                  suffix="Miles"
                />
                <ValueWithBar
                  value={milesEarned.other}
                  comparisonValue={milesEarned.total}
                  label="2X All other purchases"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="Miles"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total miles earned per{' '}
                  {multipliersReccuringInterval === 'Monthly'
                    ? 'month'
                    : 'year'}
                  :
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.round(milesEarned.total))} Miles
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Other Perks and  Benefits: How do you value them?"
        splitHeading={true}
        colorClassName="border-b-[#0B3B5D]"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col 2xl:grid grid-cols-2 px-6 pt-10 pb-5 gap-5 2xl:gap-0">
            {perksItems.map((perksItem, idx) => (
              <CalculatorInputGroup
                key={`${perksItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...perksItem}
                stateObj={perks}
                stateKey="perks"
                dispatch={dispatch}
                error={perksError}
                setError={setPerksError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>Value of other perks and benefits:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={airportPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Airport perks"
                  colorClassName="bg-theme-blue-dark"
                  prefix="$"
                />
                <ValueWithBar
                  value={hotelPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Hotel perks"
                  colorClassName="bg-[#538DFF]"
                  prefix="$"
                />
                <ValueWithBar
                  value={otherPerksValue}
                  comparisonValue={totalPerksValue}
                  label="All other benefits"
                  colorClassName="bg-[#C4FAFA]"
                  prefix="$"
                />
              </div>
              <div className="flex justify-between">
                <span>Total value:</span>
                <span className="text-theme-blue-dark">
                  ${formatInt(Math.round(totalPerksValue))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-[#0B3B5D]"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-5">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
              header={
                <div className="flex flex-col gap-1">
                  <p>
                    Capital One gives you many ways to redeem your miles. Here
                    are some common examples:
                  </p>
                  <ul className="list-disc list-inside">
                    <li>Miles are denoted as CPM = "cents per mile"</li>
                  </ul>
                </div>
              }
              units="CPM"
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <h4>Given how you value miles...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of miles from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(categoriesSpendValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Total value of miles:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(categoriesSpendValue + bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-1 items-start">
                    Value of anniversary bonus miles:
                    <Tooltip
                      content={
                        <span className="text-sm">
                          Get 10,000 bonus miles starting on your first card
                          anniversary.
                        </span>
                      }
                      childrenClassName="p-0"
                    >
                      {' '}
                      <IoInformationCircleOutline />
                    </Tooltip>
                  </div>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(anniversaryBonusValue))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={0}
              bonusValue={bonusValue}
              calculationExplanationText="(Intro offer + credits + spend - annual fee)"
              containerClassName="text-white"
              bgColorClassName="bg-[#0B3B5D]"
              headingColorClassName="text-white"
              year1Value={year1Value}
              year2Value={year2Value}
              heading="Expected Value of Miles"
            />
          </div>
        </div>
      </CalculatorSection>
      {calculator.data?.attributes.footerContent && (
        <div className="border-t p-6">
          <HTMLContent
            content={calculator.data.attributes.footerContent}
            className="prose-sm prose-p:my-2 prose-p:leading-5"
          />
        </div>
      )}
    </CalculatorStructure>
  );
}
