import { cn } from '@cardo/lib';

type CalculatorSectionProps = {
  heading: string;
  rightSideHeading?: React.ReactNode;
  splitHeading?: boolean;
  children: React.ReactNode;
  colorClassName?: string;
};

export default function CalculatorSection({
  heading,
  rightSideHeading,
  splitHeading,
  children,
  colorClassName = 'border-b-theme-blue-dark',
}: CalculatorSectionProps) {
  return (
    <>
      {!splitHeading && (
        <div className={cn('py-2 px-4 border-b-4', colorClassName)}>
          <h4>{heading}</h4>
        </div>
      )}
      {splitHeading && (
        <div className={cn('px-4 border-b-4 flex sm:divide-x', colorClassName)}>
          <div className="flex sm:basis-1/2 py-2">
            <h4>{heading}</h4>
          </div>
          <div
            className={cn(
              'sm:flex basis-1/2',
              !rightSideHeading && 'hidden',
              rightSideHeading && 'py-2 px-4 items-end justify-center'
            )}
          >
            {rightSideHeading}
          </div>
        </div>
      )}
      <div className="flex flex-col sm:flex-row divide-y sm:divide-x">
        {children}
      </div>
    </>
  );
}
