import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import { Toggle } from '@cardo/ui';
import { cn } from '@cardo/lib';
import IntroOfferSection from '../components/IntroOfferSection';
import {
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';
import type { NumberInputItem, Redemption } from '../types';
import { useMemo } from 'react';
import HTMLContent from '../../HTMLContent';

export default function ChaseInkBusinessPreferredCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    customCPPError,
    setCustomCPPError,
    data,
    bonusValue,
    cpp,
  } = useCalculator();
  const isMonthly = multipliersReccuringInterval === 'Monthly';
  const { spendCategories } = state;
  const { spendCategoryItems, redemptionItems, annualFee } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const pointsEarned = useMemo(() => {
    const categories = [
      {
        name: 'travel',
        spend:
          (isMonthly ? spendCategories.travel : spendCategories.travel) ?? 0,
        points: 0,
      },
      {
        name: 'shippingPurchases',
        spend:
          (isMonthly
            ? spendCategories.shippingPurchases
            : spendCategories.shippingPurchases) ?? 0,
        points: 0,
      },
      {
        name: 'advertisingPurchases',
        spend:
          (isMonthly
            ? spendCategories.advertisingPurchases
            : spendCategories.advertisingPurchases) ?? 0,
        points: 0,
      },
      {
        name: 'internetCablePhoneServices',
        spend:
          (isMonthly
            ? spendCategories.internetCablePhoneServices
            : spendCategories.internetCablePhoneServices) ?? 0,
        points: 0,
      },
    ];

    let totalSpend3xCategoriesUpTo150kAnnually = 0;
    let totalSpend3xCategoriesAbove150kAnnually = 0;
    let remainingSpend = 150_000;

    for (const category of categories) {
      const categorySpendAnnually = isMonthly
        ? category.spend * 12
        : category.spend;
      const spend = Math.min(remainingSpend, categorySpendAnnually);
      totalSpend3xCategoriesUpTo150kAnnually += spend;
      remainingSpend -= spend;
      category.points = spend * 3;

      if (remainingSpend <= 0) {
        totalSpend3xCategoriesAbove150kAnnually +=
          categorySpendAnnually - spend;
      }
    }

    const totalSpendAllOtherPurchasesAnnually = isMonthly
      ? (spendCategories.allOtherPurchases ?? 0) * 12
      : spendCategories.allOtherPurchases ?? 0;

    const totalPointsEarnedAnnually =
      totalSpend3xCategoriesUpTo150kAnnually * 3 +
      totalSpend3xCategoriesAbove150kAnnually * 1 +
      totalSpendAllOtherPurchasesAnnually * 1;

    return {
      total: totalPointsEarnedAnnually,
      per3xCategory: categories.reduce<{ [key: string]: number }>(
        (acc, category) => {
          acc[category.name] = category.points;
          return acc;
        },
        {}
      ),
      above150k: totalSpend3xCategoriesAbove150kAnnually * 1,
      allOtherPurchases: totalSpendAllOtherPurchasesAnnually * 1,
    };
  }, [
    isMonthly,
    spendCategories.advertisingPurchases,
    spendCategories.allOtherPurchases,
    spendCategories.internetCablePhoneServices,
    spendCategories.shippingPurchases,
    spendCategories.travel,
  ]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((isMonthly ? pointsEarned.total * 12 : pointsEarned.total) * cpp) / 100
      );
    }
    return 0;
  }, [cpp, isMonthly, pointsEarned.total]);

  const totalValuePerYear = useMemo(() => {
    if (
      !state.redemption.selectedOption ||
      (state.redemption.selectedOption === 'custom' &&
        !state.redemption.customValue) ||
      cpp === 0 ||
      customCPPError
    )
      return 0;
    return categoriesSpendValue - annualFee;
  }, [
    state.redemption.selectedOption,
    state.redemption.customValue,
    cpp,
    customCPPError,
    categoriesSpendValue,
    annualFee,
  ]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="Ink Business Preferred® Credit Card"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="Ink Business Preferred® Credit Card Intro Offer"
            colorClassName="border-b-theme-blue-dark"
            introOffer={calculator.data?.attributes.introOfferSection}
            belowPointsEarnedContent={
              calculator.data?.attributes.introOfferSection
                .belowPointsEarnedContent && (
                <HTMLContent
                  content={
                    calculator.data?.attributes.introOfferSection
                      .belowPointsEarnedContent
                  }
                  className="prose-sm prose-p:text-xs"
                />
              )
            }
            labelOverride={
              <>
                The current public offer is{' '}
                {formatInt(
                  calculator.data?.attributes.introOfferSection
                    ?.defaultBonusAmount
                )}{' '}
                Chase Ultimate Rewards points (UR). What bonus are you
                considering?
              </>
            }
            isMR={false}
            publicOfferInputLabelPrefix="UR"
          />
        )}
      <CalculatorSection
        heading="Point Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-10 px-6 py-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-theme-blue-dark"
                selectedOptionClassName="text-white"
              />
            </div>
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
          </div>
          <div className="flex basis-1/2 items-center justify-center gap-3 px-6 py-12">
            <div className="flex flex-grow flex-col justify-center gap-12 px-6 py-12">
              <p>
                {multipliersReccuringInterval} points earned from spend
                breakdown:
              </p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.per3xCategory.travel / 12
                      : pointsEarned.per3xCategory.travel
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="3X Travel*"
                  colorClassName="bg-theme-blue-dark"
                  suffix="UR"
                />
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.per3xCategory.shippingPurchases / 12
                      : pointsEarned.per3xCategory.shippingPurchases
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="3X Shipping purchases*"
                  colorClassName="bg-[#538DFF]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.per3xCategory.advertisingPurchases / 12
                      : pointsEarned.per3xCategory.advertisingPurchases
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="3X Ads*"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.per3xCategory.internetCablePhoneServices /
                        12
                      : pointsEarned.per3xCategory.internetCablePhoneServices
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="3X Internet, cable, and phone services*"
                  colorClassName="bg-[#7A65FA]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.above150k / 12
                      : pointsEarned.above150k
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="1X After $150,000 3X category threshold"
                  colorClassName="bg-[#BCB2F9]"
                  suffix="UR"
                />
                <ValueWithBar
                  value={
                    isMonthly
                      ? pointsEarned.allOtherPurchases / 12
                      : pointsEarned.allOtherPurchases
                  }
                  comparisonValue={
                    isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                  }
                  label="1X All other purchases"
                  colorClassName="bg-[#FF77B4]"
                  suffix="UR"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total points earned per {isMonthly ? 'month' : 'year'}:
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(
                    Math.floor(
                      isMonthly ? pointsEarned.total / 12 : pointsEarned.total
                    )
                  )}{' '}
                  UR
                </span>
              </div>
              <p>
                *Earn 3 points per $1 on the first $150,000 spent in combined
                purchases in the following categories each account anniversary
                year; then 1X after that.
              </p>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-5 px-6 py-10">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
              header={
                <div className="flex flex-col gap-1">
                  <p>
                    Chase gives you many ways to redeem your points. Here are
                    some common examples:
                  </p>
                  <ul className="list-inside list-disc">
                    <li>Points are denoted as CPP = "cents per point"</li>
                  </ul>
                </div>
              }
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col justify-center gap-12 p-6">
              <h4>Given how you value points...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of points from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.floor(bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.floor(categoriesSpendValue))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              heading="Expected Value of Points"
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={totalValuePerYear}
              bonusValue={bonusValue}
              calculationExplanationText="(Intro offer + spend - annual fee)"
              containerClassName="text-white"
              bgColorClassName="bg-theme-blue-dark"
              headingColorClassName="text-white"
            />
          </div>
        </div>
      </CalculatorSection>
    </CalculatorStructure>
  );
}
