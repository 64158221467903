import { cn } from '@cardo/lib';
import { useState } from 'react';

type ToggleProps = {
  options: string[] | number[];
  onChange: (value: number) => void;
  value: number;
  selectorClassName?: string;
  selectedOptionClassName?: string;
};

export function Toggle({
  options,
  onChange,
  value,
  selectorClassName,
  selectedOptionClassName,
}: ToggleProps) {
  if (options?.length !== 2) {
    throw new Error('toggle must have exactly two options');
  }

  const [localSelected, setLocalSelected] = useState(0);

  const selected = value || localSelected;

  function handleClick(idx: number) {
    if (value != null) {
      onChange(idx);
    } else {
      setLocalSelected(idx);
    }
  }

  return (
    <div className="relative flex justify-between">
      <div className="absolute inset-0 flex border rounded"></div>

      <button
        type="button"
        className={cn(
          'flex w-1/2 justify-center z-20 text-sm px-4 py-1 whitespace-nowrap',
          selected === 0 ? '' : '',
          selected === 0 ? selectedOptionClassName : ''
        )}
        onClick={() => handleClick(0)}
      >
        {options[0]}
      </button>
      <button
        type="button"
        className={cn(
          'flex w-1/2 justify-center z-20 text-sm px-4 py-1 whitespace-nowrap',
          selected === 1 ? '' : '',
          selected === 1 ? selectedOptionClassName : ''
        )}
        onClick={() => handleClick(1)}
      >
        {options[1]}
      </button>
      <div
        className={cn(
          'absolute w-1/2 inset-y-0 rounded transition-transform px-4',
          selected === 0 ? '' : 'translate-x-full',
          selectorClassName
        )}
      ></div>
    </div>
  );
}
