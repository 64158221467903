import type { ChangeEvent } from 'react';
import type { IntroOfferSectionComponent } from '@cardo/types';
import { Capitalize, CalculatorInput } from '@cardo/ui';
import { useCalculator } from '~/hooks/useCalculator';
import { formatInt } from '~/lib/utils';
import { validateNumberInput } from '../validation';
import CalculatorSection from './CalculatorSection';
import EligibilityQuestion from './EligibilityQuestion';
import { cn } from '@cardo/lib';

type IntroOfferSectionProps = {
  heading: string;
  colorClassName?: string;
  introOffer: IntroOfferSectionComponent;
  showBonusOfferInput?: boolean;
  isMR?: React.ReactNode;
  belowPointsEarnedContent?: React.ReactNode;
  labelOverride?: React.ReactNode;
  publicOfferAmountSuffix?: React.ReactNode;
  publicOfferInputLabelPrefix?: React.ReactNode;
};

export default function IntroOfferSection({
  heading,
  colorClassName,
  introOffer,
  showBonusOfferInput = true,
  isMR = true,
  belowPointsEarnedContent,
  labelOverride,
  publicOfferAmountSuffix,
  publicOfferInputLabelPrefix,
}: IntroOfferSectionProps) {
  const { state, dispatch, earnedIntroOfferAmount } = useCalculator();

  return (
    <CalculatorSection heading={heading} colorClassName={colorClassName}>
      <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
        <div className="flex basis-1/2 flex-col gap-10 px-6 pb-5 pt-10">
          {introOffer.eligibilityQuestions.map((question) => (
            <EligibilityQuestion
              key={question.questionKey}
              {...question}
              dispatch={dispatch}
            />
          ))}
          {showBonusOfferInput && (
            <div className="flex flex-col gap-4">
              <p>
                {labelOverride ?? (
                  <>
                    The current public offer is{' '}
                    {formatInt(introOffer.defaultBonusAmount)}
                    {isMR && ' MR'}
                    {!isMR && publicOfferAmountSuffix
                      ? publicOfferAmountSuffix
                      : ''}
                    . What bonus are you considering?
                  </>
                )}
              </p>
              <div className="flex flex-col gap-1.5">
                <div className="flex items-center gap-2 sm:gap-4">
                  <div className="flex flex-grow">
                    <CalculatorInput
                      type="text"
                      name="bonusOfferAmount"
                      value={state.introOffer.bonusOfferAmount}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const parsedValue = e.target.value
                          ? parseInt(e.target.value.replace(/,/g, ''))
                          : 0;
                        validateNumberInput({
                          value: parsedValue,
                          name: 'bonusOfferAmountError',
                          setError: (name, msg) =>
                            dispatch({
                              type: 'update',
                              category: 'introOffer',
                              name,
                              value: msg,
                            }),
                          validation: {
                            min: introOffer.minBonusAmount ?? 0,
                            max: introOffer.maxBonusAmount ?? 200000,
                          },
                        });
                        dispatch({
                          type: 'update',
                          category: 'introOffer',
                          name: 'bonusOfferAmount',
                          value: isNaN(parsedValue) ? null : parsedValue,
                        });
                      }}
                      className="flex flex-grow"
                    />
                  </div>
                  <div className="flex whitespace-nowrap">
                    {isMR && <span className="mr-1">MR</span>}
                    {!isMR && publicOfferInputLabelPrefix
                      ? publicOfferInputLabelPrefix
                      : ''}
                    <Capitalize className="ml-1">
                      {introOffer.offerUnits}
                    </Capitalize>
                  </div>
                </div>
                {!state.introOffer.bonusOfferAmountError && (
                  <div className="h-5 w-full" />
                )}
                {state.introOffer.bonusOfferAmountError && (
                  <div className="text-sm text-slate-500">
                    {state.introOffer.bonusOfferAmountError}
                  </div>
                )}
              </div>
            </div>
          )}
          {!showBonusOfferInput && <div className="h-1 w-full" />}
        </div>
        <div
          className={cn(
            'flex basis-1/2 flex-col items-center justify-center gap-3 px-8',
            belowPointsEarnedContent ? 'pt-10' : 'py-10'
          )}
        >
          <div className="flex flex-grow flex-col items-center justify-center gap-3">
            <h4 className="max-w-[300px] text-center">
              <span className="inline-block first-letter:uppercase">
                {introOffer.offerUnits}
              </span>{' '}
              earned from intro offer:
            </h4>
            <span className="text-theme-blue-dark text-2xl font-bold">
              {introOffer.offerUnits === 'cash back' && '$'}
              {formatInt(earnedIntroOfferAmount)}
            </span>
          </div>
          {belowPointsEarnedContent && (
            <div className="flex flex-shrink flex-grow-0">
              {belowPointsEarnedContent}
            </div>
          )}
        </div>
      </div>
    </CalculatorSection>
  );
}
