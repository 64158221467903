import { useMemo } from 'react';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import { Toggle } from '@cardo/ui';
import { cn } from '@cardo/lib';
import IntroOfferSection from '../components/IntroOfferSection';
import {
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';
import type { NumberInputItem, Redemption } from '../types';

export default function AmericanExpressPlatinumCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    creditsError,
    setCreditsError,
    perksError,
    setPerksError,
    customCPPError,
    setCustomCPPError,
    totalCreditsValue,
    data,
    cpp,
    bonusValue,
  } = useCalculator();
  const { spendCategories, credits, perks } = state;
  const {
    spendCategoryItems,
    creditsItems,
    perksItems,
    redemptionItems,
    annualFee,
  } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      creditsItems: NumberInputItem[];
      perksItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const {
    airportPerksValue,
    hotelPerksValue,
    otherPerksValue,
    totalPerksValue,
  }: {
    airportPerksValue: number;
    hotelPerksValue: number;
    otherPerksValue: number;
    totalPerksValue: number;
  } = useMemo(() => {
    const {
      centurionLoungePerk,
      deltaSkyClubPerk,
      hotelStatusPerk,
      fineHotelsPerk,
      ...otherPerks
    } = perks;
    const airportPerksValue =
      (centurionLoungePerk || 0) + (deltaSkyClubPerk || 0);
    const hotelPerksValue = (hotelStatusPerk || 0) + (fineHotelsPerk || 0);
    const otherPerksValue =
      Object.values(otherPerks).reduce((acc, otherPerkValue) => {
        if (typeof otherPerkValue === 'number' && acc != null) {
          return acc + otherPerkValue;
        }
        return acc;
      }, 0) || 0;
    const totalPerksValue =
      airportPerksValue + hotelPerksValue + otherPerksValue;
    return {
      airportPerksValue,
      hotelPerksValue,
      otherPerksValue,
      totalPerksValue,
    };
  }, [perks]);

  const pointsEarned: {
    flights: number;
    hotels: number;
    other: number;
    total: number;
  } = useMemo(() => {
    const { flights, hotels, other } = spendCategories;
    let flightsPoints = (flights || 0) * 5;
    let hotelsPoints = (hotels || 0) * 5;
    let otherPoints = other || 0;

    return {
      flights: flightsPoints,
      hotels: hotelsPoints,
      other: otherPoints,
      total: flightsPoints + hotelsPoints + otherPoints,
    };
  }, [spendCategories]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? pointsEarned.total * 12
          : pointsEarned.total) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, pointsEarned.total, multipliersReccuringInterval]);

  const totalValuePerYear = useMemo(() => {
    if (
      !state.redemption.selectedOption ||
      (state.redemption.selectedOption === 'custom' &&
        !state.redemption.customValue) ||
      cpp === 0 ||
      customCPPError
    )
      return 0;
    return (
      totalCreditsValue + categoriesSpendValue + totalPerksValue - annualFee
    );
  }, [
    totalCreditsValue,
    categoriesSpendValue,
    totalPerksValue,
    state.redemption,
    annualFee,
    customCPPError,
    cpp,
  ]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="The Platinum Card® from American Express"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="The Platinum Card® from American Express Intro Offer"
            colorClassName="border-b-theme-blue-dark"
            introOffer={calculator.data?.attributes.introOfferSection}
            isMR={true}
          />
        )}
      <CalculatorSection
        heading="Credits: How Do You Value Them?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex flex-col 2xl:grid basis-1/2 grid-cols-2 px-6 pt-10 pb-5 gap-5 2xl:gap-0">
            {creditsItems.map((creditsItem, idx) => (
              <CalculatorInputGroup
                key={`${creditsItem.label}_${idx}`}
                className={cn(idx > 7 && '2xl:col-span-2')}
                {...creditsItem}
                stateObj={credits}
                stateKey="credits"
                dispatch={dispatch}
                error={creditsError}
                setError={setCreditsError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center gap-3 py-10 px-8">
            <p>
              The Platinum Card® from American Express annual fee is $
              {formatInt(annualFee)}.
            </p>
            <p>
              Total value of credits:{' '}
              <span className="text-theme-blue-dark text-lg ml-2">
                ${formatInt(Math.round(totalCreditsValue))}
              </span>
            </p>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Other Perks and  Benefits: How do you value them?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col 2xl:grid grid-cols-2 px-6 pt-10 pb-5 gap-5 2xl:gap-0">
            {perksItems.map((perksItem, idx) => (
              <CalculatorInputGroup
                key={`${perksItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...perksItem}
                stateObj={perks}
                stateKey="perks"
                dispatch={dispatch}
                error={perksError}
                setError={setPerksError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>Value of other perks and benefits:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={airportPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Airport perks"
                  colorClassName="bg-theme-blue-dark"
                  prefix="$"
                />
                <ValueWithBar
                  value={hotelPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Hotel perks"
                  colorClassName="bg-[#538DFF]"
                  prefix="$"
                />
                <ValueWithBar
                  value={otherPerksValue}
                  comparisonValue={totalPerksValue}
                  label="All other benefits"
                  colorClassName="bg-[#C4FAFA]"
                  prefix="$"
                />
              </div>
              <div className="flex justify-between">
                <span>Total value:</span>
                <span className="text-theme-blue-dark">
                  ${formatInt(Math.round(totalPerksValue))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Point Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-theme-blue-dark"
                selectedOptionClassName="text-white"
              />
            </div>
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
          </div>
          <div className="flex basis-1/2 justify-center items-center">
            <div className="flex flex-grow flex-col px-8 py-10 gap-12 justify-center">
              <p>
                {multipliersReccuringInterval} points earned from spend
                breakdown:
              </p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={pointsEarned.flights}
                  comparisonValue={pointsEarned.total}
                  label="5X Flights"
                  colorClassName="bg-theme-blue-dark"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.hotels}
                  comparisonValue={pointsEarned.total}
                  label="5X Prepaid Hotels"
                  colorClassName="bg-[#538DFF]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.other}
                  comparisonValue={pointsEarned.total}
                  label="1X All other purchases"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="MR"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total points earned per{' '}
                  {multipliersReccuringInterval === 'Monthly'
                    ? 'month'
                    : 'year'}
                  :
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.round(pointsEarned.total))} MR
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-theme-blue-dark"
      >
        <div className="flex flex-grow flex-col sm:flex-row divide-y sm:divide-y-0 sm:divide-x">
          <div className="flex basis-1/2 flex-col px-6 py-10 gap-5">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
              header={
                <div className="flex flex-col gap-1">
                  <p>
                    American Express gives you many ways to redeem your points.
                    Here are some common examples:
                  </p>
                  <ul className="list-disc list-inside">
                    <li>Points are denoted as CPP = "cents per point"</li>
                  </ul>
                </div>
              }
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col px-6 py-10 gap-12 justify-center">
              <h4>Given how you value points...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of points from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value of credits:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(totalCreditsValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(categoriesSpendValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value of perks and benefits:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(totalPerksValue))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={totalValuePerYear}
              bonusValue={bonusValue}
              calculationExplanationText="(Intro offer + credits + spend + benefits - annual fee)"
              containerClassName="text-white"
              bgColorClassName="bg-theme-blue-dark"
              headingColorClassName="text-white"
              heading="Expected Value of Points"
            />
          </div>
        </div>
      </CalculatorSection>
    </CalculatorStructure>
  );
}
