import { cn } from '@cardo/lib';
import type {
  CreditCardCategoriesComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import CreditCardCategoryCard from './CreditCardCategoryCard';

export default function CreditCardCategories({
  heading,
  subheading,
  categories,
}: OmitComponentAttrs<CreditCardCategoriesComponent>) {
  return (
    <div className="flex flex-col flex-grow items-center">
      {heading && (
        <h2
          className={cn('text-center', {
            'mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-14">{subheading}</h5>}
      {categories && categories.length > 0 && (
        <div className="grid grid-cols-3 gap-2 sm:gap-4">
          {categories.map((category) => (
            <CreditCardCategoryCard
              key={category.category.data?.attributes.slug}
              category={category.category.data}
            />
          ))}
        </div>
      )}
    </div>
  );
}
