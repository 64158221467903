import { cn } from '@cardo/lib';
import { formatInt } from '~/lib/utils';

type ValueWithBarProps = {
  value: number;
  comparisonValue: number;
  label: string;
  colorClassName?: string;
  prefix?: string;
  suffix?: string;
};

export default function ValueWithBar({
  value,
  comparisonValue,
  label,
  colorClassName,
  prefix,
  suffix,
}: ValueWithBarProps) {
  const width = comparisonValue > 0 ? (value / comparisonValue) * 100 : 1;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <span>{label}</span>
        <span className="text-theme-blue-dark">
          <span className="text-theme-blue-dark">
            {prefix}
            {formatInt(Math.floor(value))}
            {suffix ? ` ${suffix}` : ''}
          </span>
        </span>
      </div>
      <div
        className={cn(
          'rounded h-6 transition-all',
          colorClassName,
          !colorClassName && 'border'
        )}
        style={{
          width: `${width > 0 ? width : 0.5}%`,
        }}
      />
    </div>
  );
}
