import { useMemo } from 'react';
import type { CalculatorComponent, OmitComponentAttrs } from '@cardo/types';
import { formatInt } from '~/lib/utils';
import CalculatorSection from '../components/CalculatorSection';
import CalculatorStructure from '../components/CalculatorStructure';
import { Toggle } from '@cardo/ui';
import { cn } from '@cardo/lib';
import IntroOfferSection from '../components/IntroOfferSection';
import {
  getValue,
  multipliersReccuringIntervalOptions,
  useCalculator,
} from '~/hooks/useCalculator';
import ExpectedValue from '../components/ExpectedValue';
import RedemptionRadios from '../components/RedemptionRadios';
import CalculatorInputGroup from '~/components/input/CalculatorInputGroup';
import ValueWithBar from '../components/ValueWithBar';
import type { NumberInputItem, Redemption } from '../types';
import HTMLContent from '../../HTMLContent';

export default function AmericanExpressBusinessPlatinumCalculator({
  heading,
  calculator,
  showCalculationMethodology,
}: OmitComponentAttrs<
  CalculatorComponent & { showCalculationMethodology?: boolean }
>) {
  const {
    state,
    dispatch,
    hasIntroOffer,
    earnedIntroOffer,
    multipliersReccuringInterval,
    setMultipliersRecurringInterval,
    creditsError,
    setCreditsError,
    perksError,
    setPerksError,
    customCPPError,
    setCustomCPPError,
    totalCreditsValue,
    data,
    cpp,
    bonusValue,
  } = useCalculator();
  const { spendCategories, credits, perks } = state;
  const {
    spendCategoryItems,
    creditsItems,
    perksItems,
    redemptionItems,
    annualFee,
  } =
    (data as {
      spendCategoryItems: NumberInputItem[];
      creditsItems: NumberInputItem[];
      perksItems: NumberInputItem[];
      redemptionItems: Redemption[];
      annualFee: number;
    }) ?? {};

  const {
    airportPerksValue,
    hotelPerksValue,
    otherPerksValue,
    totalPerksValue,
  }: {
    airportPerksValue: number;
    hotelPerksValue: number;
    otherPerksValue: number;
    totalPerksValue: number;
  } = useMemo(() => {
    const {
      centurionLoungePerk,
      deltaSkyClubPerk,
      priorityPassPerk,
      hotelStatusPerk,
      fineHotelsPerk,
      ...otherPerks
    } = perks;
    const airportPerksValue =
      getValue(data, state, 'perks', 'centurionLoungePerk') +
      getValue(data, state, 'perks', 'deltaSkyClubPerk') +
      getValue(data, state, 'perks', 'priorityPassPerk');

    const hotelPerksValue =
      getValue(data, state, 'perks', 'hotelStatusPerk') +
      getValue(data, state, 'perks', 'fineHotelsPerk');
    const otherPerksValue = Object.entries(otherPerks).reduce(
      (acc, [key, value]) => {
        if (typeof value === 'number') {
          return acc + getValue(data, state, 'perks', key);
        }
        return acc;
      },
      0
    );
    const totalPerksValue =
      airportPerksValue + hotelPerksValue + otherPerksValue;
    return {
      airportPerksValue,
      hotelPerksValue,
      otherPerksValue,
      totalPerksValue,
    };
  }, [data, perks, state]);

  const pointsEarned: {
    flightsAndHotels: number;
    purchasesOf5000: number;
    keyBusinessCategories: number;
    allOtherPurchases: number;
    total: number;
  } = useMemo(() => {
    const {
      flightsAndHotels,
      purchasesOf5000,
      keyBusinessCategories,
      allOtherPurchases,
    } = spendCategories;

    const flightsAndHotelsPoints = (flightsAndHotels ?? 0) * 5;

    const purchasesOf5000PointsOver2Million = Math.max(
      (purchasesOf5000 ?? 0) - 2_000_000,
      0
    );
    const purchasesOf5000PointsUnder2Million = Math.max(
      (purchasesOf5000 ?? 0) - purchasesOf5000PointsOver2Million - 4_999,
      0
    );
    const purchasesOf5000PointsUnder5000 = Math.max(
      (purchasesOf5000 ?? 0) - purchasesOf5000PointsUnder2Million,
      0
    );
    const purchasesOf5000Points =
      (purchasesOf5000PointsUnder5000 ?? 0) * 1 +
      (purchasesOf5000PointsUnder2Million ?? 0) * 1.5 +
      (purchasesOf5000PointsOver2Million ?? 0) * 1;

    const keyBusinessCategoriesPointsOver2Million = Math.max(
      (keyBusinessCategories ?? 0) - 2_000_000,
      0
    );
    const keyBusinessCategoriesPointsUnder2Million =
      (keyBusinessCategories ?? 0) - keyBusinessCategoriesPointsOver2Million;
    const keyBusinessCategoriesPoints =
      (keyBusinessCategoriesPointsUnder2Million ?? 0) * 1.5 +
      (keyBusinessCategoriesPointsOver2Million ?? 0) * 1;

    const allOtherPurchasesPoints = (allOtherPurchases ?? 0) * 1;

    return {
      flightsAndHotels: flightsAndHotelsPoints,
      purchasesOf5000: purchasesOf5000Points,
      keyBusinessCategories: keyBusinessCategoriesPoints,
      allOtherPurchases: allOtherPurchasesPoints,
      total:
        flightsAndHotelsPoints +
        purchasesOf5000Points +
        keyBusinessCategoriesPoints +
        allOtherPurchasesPoints,
    };
  }, [spendCategories]);

  const categoriesSpendValue = useMemo(() => {
    if (cpp) {
      return (
        ((multipliersReccuringInterval === 'Monthly'
          ? pointsEarned.total * 12
          : pointsEarned.total) *
          cpp) /
        100
      );
    }
    return 0;
  }, [cpp, pointsEarned.total, multipliersReccuringInterval]);

  const totalValuePerYear = useMemo(() => {
    if (
      !state.redemption.selectedOption ||
      (state.redemption.selectedOption === 'custom' &&
        !state.redemption.customValue) ||
      cpp === 0 ||
      customCPPError
    )
      return 0;
    return (
      totalCreditsValue + categoriesSpendValue + totalPerksValue - annualFee
    );
  }, [
    totalCreditsValue,
    categoriesSpendValue,
    totalPerksValue,
    state.redemption,
    annualFee,
    customCPPError,
    cpp,
  ]);

  return (
    <CalculatorStructure
      heading={heading}
      cardName="The Business Platinum Card® from American Express"
      showCalculationMethodology={showCalculationMethodology}
    >
      {hasIntroOffer &&
        calculator.data?.attributes.introOfferSection && ( // extra check just to make ts happy
          <IntroOfferSection
            heading="The Business Platinum Card® from American Express Intro Offer"
            colorClassName="border-b-[#0652DD]"
            introOffer={calculator.data?.attributes.introOfferSection}
            isMR={true}
            belowPointsEarnedContent={
              calculator.data?.attributes.introOfferSection
                .belowPointsEarnedContent && (
                <HTMLContent
                  content={
                    calculator.data?.attributes.introOfferSection
                      .belowPointsEarnedContent
                  }
                  className="prose-sm prose-p:text-xs"
                />
              )
            }
          />
        )}
      <CalculatorSection
        heading="Credits: How Do You Value Them?"
        splitHeading={true}
        colorClassName="border-b-[#0652DD]"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 grid-cols-2 flex-col gap-5 px-6 pb-5 pt-10 2xl:grid 2xl:gap-0">
            {creditsItems.map((creditsItem, idx) => (
              <CalculatorInputGroup
                key={`${creditsItem.label}_${idx}`}
                className={cn((idx > 7 || idx < 2) && '2xl:col-span-2')}
                {...creditsItem}
                stateObj={credits}
                stateKey="credits"
                dispatch={dispatch}
                error={creditsError}
                setError={setCreditsError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center gap-3 px-8 py-10">
            <p>
              The Business Platinum Card® from American Express annual fee is $
              {formatInt(annualFee)}.
            </p>
            <p>
              Total value of credits:{' '}
              <span className="text-theme-blue-dark ml-2 text-lg">
                ${formatInt(Math.round(totalCreditsValue))}
              </span>
            </p>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Other Perks and  Benefits: How do you value them?"
        splitHeading={true}
        colorClassName="border-b-[#0652DD]"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 grid-cols-2 flex-col gap-5 px-6 pb-5 pt-10 2xl:grid 2xl:gap-0">
            {perksItems.map((perksItem, idx) => (
              <CalculatorInputGroup
                key={`${perksItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...perksItem}
                stateObj={perks}
                stateKey="perks"
                dispatch={dispatch}
                error={perksError}
                setError={setPerksError}
              />
            ))}
          </div>
          <div className="flex basis-1/2 flex-col justify-center">
            <div className="flex flex-grow flex-col justify-center gap-12 px-8 py-10">
              <p>Value of other perks and benefits:</p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={airportPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Airport perks"
                  colorClassName="bg-theme-blue-dark"
                  prefix="$"
                />
                <ValueWithBar
                  value={hotelPerksValue}
                  comparisonValue={totalPerksValue}
                  label="Hotel perks"
                  colorClassName="bg-[#538DFF]"
                  prefix="$"
                />
                <ValueWithBar
                  value={otherPerksValue}
                  comparisonValue={totalPerksValue}
                  label="All other benefits"
                  colorClassName="bg-[#C4FAFA]"
                  prefix="$"
                />
              </div>
              <div className="flex justify-between">
                <span>Total value:</span>
                <span className="text-theme-blue-dark">
                  ${formatInt(Math.round(totalPerksValue))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="Point Multipliers: Category Spend"
        splitHeading={true}
        colorClassName="border-b-[#0652DD]"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-10 px-6 py-10">
            <div className="flex justify-end">
              <Toggle
                options={multipliersReccuringIntervalOptions}
                onChange={setMultipliersRecurringInterval}
                value={multipliersReccuringInterval === 'Yearly' ? 0 : 1}
                selectorClassName="bg-[#0652DD]"
                selectedOptionClassName="text-white"
              />
            </div>
            {spendCategoryItems.map((spendCategoryItem, idx) => (
              <CalculatorInputGroup
                key={`${spendCategoryItem.label}_${idx}`}
                className={cn(idx > 2 && '2xl:col-span-2')}
                {...spendCategoryItem}
                stateObj={spendCategories}
                stateKey="spendCategories"
                dispatch={dispatch}
                labelInputContainerClassName="sm:flex-row"
              />
            ))}
          </div>
          <div className="flex basis-1/2 items-center justify-center">
            <div className="flex flex-grow flex-col justify-center gap-12 px-8 py-10">
              <p>
                {multipliersReccuringInterval} points earned from spend
                breakdown:
              </p>
              <div className="flex flex-col gap-6">
                <ValueWithBar
                  value={pointsEarned.flightsAndHotels}
                  comparisonValue={pointsEarned.total}
                  label="5X Flights & prepaid hotels"
                  colorClassName="bg-theme-blue-dark"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.purchasesOf5000}
                  comparisonValue={pointsEarned.total}
                  label="1.5X Purchases of $5,000+"
                  colorClassName="bg-[#538DFF]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.keyBusinessCategories}
                  comparisonValue={pointsEarned.total}
                  label="1.5X Key business categories"
                  colorClassName="bg-[#C4FAFA]"
                  suffix="MR"
                />
                <ValueWithBar
                  value={pointsEarned.allOtherPurchases}
                  comparisonValue={pointsEarned.total}
                  label="1X All other purchases"
                  colorClassName="bg-theme-purple"
                  suffix="MR"
                />
              </div>
              <div className="flex justify-between">
                <span>
                  Total points earned per{' '}
                  {multipliersReccuringInterval === 'Monthly'
                    ? 'month'
                    : 'year'}
                  :
                </span>
                <span className="text-theme-blue-dark">
                  {formatInt(Math.round(pointsEarned.total))} MR
                </span>
              </div>
            </div>
          </div>
        </div>
      </CalculatorSection>
      <CalculatorSection
        heading="How do you plan on redeeming points?"
        splitHeading={true}
        colorClassName="border-b-[#0652DD]"
      >
        <div className="flex flex-grow flex-col divide-y sm:flex-row sm:divide-x sm:divide-y-0">
          <div className="flex basis-1/2 flex-col gap-5 px-6 py-10">
            <RedemptionRadios
              redemptionItems={redemptionItems}
              redemption={state.redemption}
              dispatch={dispatch}
              setCustomCPPError={setCustomCPPError}
              customCPPError={customCPPError}
              header={
                <div className="flex flex-col gap-1">
                  <p>
                    American Express gives you many ways to redeem your points.
                    Here are some common examples:
                  </p>
                  <ul className="list-inside list-disc">
                    <li>Points are denoted as CPP = "cents per point"</li>
                  </ul>
                </div>
              }
            />
          </div>
          <div className="flex basis-1/2 flex-col gap-4">
            <div className="flex flex-grow flex-col justify-center gap-12 px-6 py-10">
              <h4>Given how you value points...</h4>
              <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                  <span>Value of points from intro offer:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(bonusValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value of credits:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(totalCreditsValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value from category spend:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(categoriesSpendValue))}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Value of perks and benefits:</span>
                  <span className="text-theme-blue-dark">
                    ${formatInt(Math.round(totalPerksValue))}
                  </span>
                </div>
              </div>
            </div>
            <ExpectedValue
              earnedIntroOffer={earnedIntroOffer}
              totalValuePerYear={totalValuePerYear}
              bonusValue={bonusValue}
              calculationExplanationText="(Intro offer + credits + spend + benefits - annual fee)"
              containerClassName="text-white"
              bgColorClassName="bg-[#0E44AC]"
              headingColorClassName="text-white"
              heading="Expected Value of Points"
            />
          </div>
        </div>
      </CalculatorSection>
      {calculator.data?.attributes.footerContent && (
        <div className="border-t p-6">
          <HTMLContent
            content={calculator.data.attributes.footerContent}
            className="prose-sm prose-p:my-2 prose-p:leading-5"
          />
        </div>
      )}
    </CalculatorStructure>
  );
}
